<template>
  <div>
    <modal name="modal-paymentdetail" @before-open="beforeOpen" with="auto" height="auto">
      <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog"
        aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" tabindex="-1"
        uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true"
        style="display: block; overflow: auto">
        <div class="modal-dialog modal-m">
          <div class="modal-content" uib-modal-transclude="">
            <div class="
                modal-content
                text-center
                onboarding-modal
                modal-centered
                ng-scope
              ">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel" style="text-align: center">
                    <span style="color: #334152">{{
                      currentEmployees.employeeData
                        ? currentEmployees.employeeData.firstName +
                        " " +
                        currentEmployees.employeeData.lastName
                        : ""
                    }}</span>
                  </h5>
                  <button v-on:click="openWarning()" aria-label="Close" class="box-title close" type="button"></button>
                </div>
                <div class="modal-body">
                  <div class="body-payment" style="overflow-y: hidden">
                    <div class="group">
                      <div class="header title-option">Ordinary work hours</div>
                      <div class="div-input">
                        <div class="group-left">
                          <div class="input-group">
                            <input v-model.number="currentEmployees.ordinaryWorkHoursRound
                              " v-on:change="onChangeWeeklyWorkHours()" type="number" class="form-control" style="" />
                            <i v-show="currentEmployees.isChangeWorkHours" class="
                                icon-feather-rotate-ccw
                                text-primary
                                ng-scope
                                icon-payroll
                                pointer
                              " @click="resetWorkHours()" style="right: 70px"></i>
                            <div class="unit">hr</div>
                          </div>
                        </div>
                        <div class="group-right">
                          <div class="input-group">
                            <input v-model.number="currentEmployees.ordinaryPayRateRound
                              " v-on:change="onChangePayRatePerHour()" type="number" class="form-control" style="" />
                            <i v-show="currentEmployees.isChangePayRate" class="
                                icon-feather-rotate-ccw
                                text-primary
                                ng-scope
                                icon-payroll
                                pointer
                              " @click="resetPayRate()" style="right: 70px"></i>
                            <div class="unit">$</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group" v-show="listBonous.length > 0" v-for="(item, index) in listBonous"
                      :key="item.id">
                      <div class="col-12">
                        <hr />
                      </div>
                      <div class="header">
                        <div class="title-option mr-3">Bonus / Commission</div>
                        <div class="text-danger text-right" ng-hide="payslip.co" style="margin-top: 0px">
                          <i class="icon-feather-trash-2 pointer" v-on:click="removeBonus(index)"></i>
                        </div>
                      </div>
                      <div class="div-input">
                        <div class="group-left">
                          <div class="form-group text-left">
                            <label>Type</label>
                            <select v-model="item.type" class="form-control" name="state">
                              <option value="EXEMPT_TO_SUPER">
                                Exempt from super
                              </option>
                              <option value="SUBJECT_TO_SUPER">
                                Subject to super
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="group-right">
                          <div class="form-group text-left">
                            <label>Amount</label>
                            <div class="input-group">
                              <input v-model.number="item.amountBonus" type="number" step=".0001"
                                class="form-control" />
                              <div class="unit">$</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group" v-show="listExtraHours.length > 0" v-for="(item, index) in listExtraHours"
                      :key="item.id">
                      <div class="col-12">
                        <hr />
                      </div>
                      <div class="header">
                        <div class="title-option mr-3">Earnings</div>
                        <div class="text-danger text-right" ng-hide="payslip.co" style="margin-top: 0px">
                          <i class="icon-feather-trash-2 pointer" v-on:click="removeExtrahours(index)"></i>
                        </div>
                      </div>
                      <div class="div-input">
                        <div class="group-left">
                          <div class="form-group text-left">
                            <label>Option</label>
                            <select v-model="item.option" class="form-control" name="state">
                              <option v-if="!item.isExtraHourVisible" :value="item.option">
                                {{(item.option).toLowerCase().replace(/(?:^|\s|-)\S/g, x =>
                                  x.toUpperCase()).split('_').join(' ')}}
                              </option>
                              <option v-if="item.isExtraHourVisible" value="ORDINARY_HOURS">
                                Ordinary hours
                              </option>
                              <option v-if="item.isExtraHourVisible" value="OVERTIME_EXEMPT_FROM_SUPER">
                                Overtime - exempt from super
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="group-right">
                          <div class="form-group text-left">
                            <label>Name <span class="text-danger">*</span></label>
                            <input v-model="item.name" type="text" :readonly="!item.isExtraHourVisible"
                              class="form-control" :class="'ng-name' + index" />
                          </div>
                        </div>
                      </div>
                      <div class="div-input">
                        <div class="group-left">
                          <div class="form-group text-left">
                            <label>Hour</label>
                            <div class="input-group">
                              <input v-model.number="item.hour" type="number" :class="'ng-hour' + index" step=".0001"
                                class="form-control hoursExtra" />
                              <div class="unit">hr</div>
                            </div>
                          </div>
                        </div>
                        <div class="group-right">
                          <div class="form-group text-left">
                            <label>Rate</label>
                            <div class="input-group">
                              <input v-model.number="item.normalRate" type="number" :readonly="!item.isExtraHourVisible"
                                step=".0001" class="form-control" />
                              <div class="unit">$</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group" v-show="listAllowance.length > 0" v-for="(item, index) in listAllowance"
                      :key="item.id">
                      <div class="col-12">
                        <hr />
                      </div>
                      <div class="header">
                        <div class="title-option mr-3">Allowance</div>
                        <div class="text-danger text-right" ng-hide="payslip.co" style="margin-top: 0px">
                          <i class="icon-feather-trash-2 pointer" @click="
                            item.type == 'JOBKEEPER'
                              ? removeJobKeeper(index)
                              : removeAllowance(index)"></i>
                        </div>
                      </div>
                      <div class="div-input">
                        <div class="group-left">
                          <div class="input-group">
                            <input v-model.number="item.unit" type="number" step=".0001" class="form-control" />
                            <div class="unit">unit</div>
                          </div>
                        </div>
                        <div class="group-right">
                          <div class="input-group">
                            <input v-model.number="item.cost" type="number" step=".0001" class="form-control" />
                            <div class="unit">$</div>
                          </div>
                        </div>
                      </div>
                      <div class="div-input">
                        <div class="group-left">
                          <div class="form-group text-left">
                            <label>Option</label>
                            <select v-model="item.option" class="form-control" name="state">

                              <option v-if="!item.isAllowanceVisible" :value="item.option">
                                {{(item.option).toLowerCase().replace(/(?:^|\s|-)\S/g, x =>
                                  x.toUpperCase()).split('_').join(' ')}}
                              </option>
                              <option v-if="item.isAllowanceVisible" value="SUBJECT_TO_TAX_AND_SUPER">
                                Subject to tax, Exempt from super
                              </option>
                              <option v-if="item.isAllowanceVisible" value="EXEMPT_FROM_TAX_AND_SUPER">
                                Exempt from tax and super
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="group-right">
                          <div class="form-group text-left">
                            <label>Type</label>

                            <select v-on:change="choseTypeAllowance(item)" v-model="item.type" class="form-control"
                              name="state">
                              <option :value="item.type" v-if="!item.isAllowanceVisible">
                                {{item.type === 'OTHER' ?
                                  (item.otherName).split('_').join('').toLowerCase().replace(/(?:^|\s|-)\S/g, x =>
                                    x.toUpperCase()) : (item.type).toLowerCase().replace(/(?:^|\s|-)\S/g, x =>
                                      x.toUpperCase())}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div v-show="item.type == 'JOBKEEPER'">
                        <div class="div-input">
                          <div class="group-left">
                            <div class="form-group text-left">
                              <label>
                                Started
                                <span class="text-danger">*</span>
                                <span helper="" class="box-title jobkeeper-start"><i class="
                                      icon-feather-help-circle
                                      text-primary
                                      fs-14
                                      pointer
                                    " style="vertical-align: middle"></i></span>
                              </label>
                              <select v-on:change="chooseStartJobKeeper(start)" v-model="start"
                                class="form-control ng-startFN" name="state">
                                <option value="" selected>
                                  Select the option
                                </option>
                                <option v-for="opt in listFN" :key="opt.id" :value="opt">
                                  {{ opt.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="group-right">
                            <div class="form-group text-left">
                              <label>
                                Finished
                                <span class="box-title jobkeeper-finish" helper=""><i class="
                                      icon-feather-help-circle
                                      text-primary
                                      fs-14
                                      pointer
                                    " style="vertical-align: middle"></i></span>
                              </label>
                              <select v-on:change="chooseFinishJobKeeper(finish)" v-model="finish"
                                class="form-control ng-finishFN" name="state">
                                <option value="" selected>
                                  Select the option
                                </option>
                                <option v-for="opt in listFN" :key="opt.id" :value="opt">
                                  {{ opt.name }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Termination Payment -->
                    <div class="group">
                      <div v-if="listTermination.length > 0">
                        <div class="col-12">
                          <hr />
                        </div>
                        <div class="header">
                          <div class="title-option mr-3">
                            Termination Payment
                          </div>
                          <div class="text-danger text-right" ng-hide="payslip.co" style="margin-top: 0px">
                            <i class="icon-feather-trash-2 pointer" v-on:click="removeTermination(0)"></i>
                          </div>
                        </div>
                        <div v-show="listTermination.length > 0" v-for="(item, index) in listTermination" :key="index">
                          <div class="div-input">
                            <div class="group-left">
                              <div class="form-group">
                                <label>End date</label>
                                <div class="small-label">
                                  <span class=" text-black ng-binding" style="font-size:17px">{{
                                    formatDateNumbers(terminationDate) }}</span>
                                </div>

                              </div>
                            </div>
                            <div class="group-right">
                              <div class="form-group">
                                <label>Type</label>
                                <select v-model="item.type" class="form-control" name="state">
                                  <option value="NORMAL">
                                    Normal termination
                                  </option>
                                  <option value="GENUINE">
                                    Genuine redundancy
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="div-input">
                            <div class="group-left">
                              <div class="form-group">
                                <label>Unused leave</label>
                                <div class="input-group">
                                  <input disabled v-model.number="item.unusedLeave" type="number"
                                    class="form-control" />
                                  <div class="unit">hr</div>
                                </div>
                              </div>
                            </div>
                            <div class="group-right">
                              <div class="input-group" style="margin-top: 25.6px">
                                <input v-model.number="item.amountTermination" type="number" step="0"
                                  class="form-control" />
                                <div class="unit">$</div>
                              </div>
                            </div>
                          </div>
                          <div class="div-input">
                            <div class="group-left" v-show="item.type == 'GENUINE'">
                              <div class="form-group">
                                <label>Redundancy</label>
                                <div class="input-group">
                                  <input v-model.number="item.redundancy" type="number" step="0" class="form-control" />
                                  <div class="unit">$</div>
                                </div>
                              </div>
                            </div>
                            <div v-bind:class="item.type == 'GENUINE'
                              ? 'group-right'
                              : 'group-left'
                              " v-if="item.isShowETP">
                              <div class="form-group">
                                <label>ETP Type 'O'</label>
                                <div class="input-group">
                                  <input v-model.number="item.etpType" type="number" step="0" class="form-control" />
                                  <div class="unit">$</div>
                                </div>
                              </div>
                            </div>
                            <div v-bind:class="item.type == 'GENUINE'
                              ? 'group-right'
                              : 'group-left'
                              " v-else style="display: flex; align-items: center">
                              <div class="col-12 text-primary os-dropdown-trigger" v-on:click="item.isShowETP = true">
                                + ETP Type 'O'
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Deductions -->
                    <div class="group" v-show="listDeduction.length > 0" v-for="(item, index) in listDeduction"
                      :key="item.id">
                      <div class="col-12">
                        <hr />
                      </div>
                      <div class="header">
                        <div class="title-option mr-3">Deduction</div>
                        <div class="text-danger text-right" ng-hide="payslip.co" style="margin-top: 0px">
                          <i class="icon-feather-trash-2 pointer" v-on:click="removeDeduction(index)"></i>
                        </div>
                      </div>
                      <div class="div-input">
                        <div class="group-left">
                          <div class="form-group text-left">
                            <label>Option</label>
                            <select v-model="item.option" @change="onChangeOptioneDuction($event, index)"
                              class="form-control" name="state">
                              <option v-if="!item.isDeductionVisible" :value="item.option">
                                {{(item.option).toLowerCase().replace(/(?:^|\s|-)\S/g, x =>
                                  x.toUpperCase()).split('_').join(' ')}}
                              </option>
                              <option v-if="item.isDeductionVisible" value="PRE_TAX_DEDUCTION">
                                Pre tax deduction
                              </option>
                              <option v-if="item.isDeductionVisible" value="POST_TAX_DEDUCTION">
                                Post tax deduction
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="group-right">
                          <div class="form-group text-left">
                            <label>Type</label>
                            <select v-model="item.type" class="form-control" name="state">
                              <option :value="item.type" v-if="!item.isDeductionVisible">
                                {{item.type === 'OTHER' ?
                                  (item.otherName).split('_').join('').toLowerCase().replace(/(?:^|\s|-)\S/g, x =>
                                    x.toUpperCase()) : (item.type).toLowerCase().replace(/(?:^|\s|-)\S/g, x =>
                                      x.toUpperCase())}}
                              </option>
                              <option v-if="item.isDeductionVisible" value="FEES">Fees</option>
                              <option v-if="item.isDeductionVisible" value="WORKPLACE_GIVING">
                                Workplace Giving
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="group-right">
                          <div class="input-group" style="margin-top: 25.6px">
                            <input v-model.number="item.amountDeduction" type="number" step="0" class="form-control" />
                            <div class="unit">$</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Leave -->
                    <div class="group" v-show="listLeaveDetail.length > 0">
                      <div class="col-12">
                        <hr />
                      </div>
                      <div class="header">
                        <div class="title-option mr-3">
                          Annual Leave
                        </div>
                        <!-- <div
                            class="text-danger text-right"
                            style="margin-top: 0px"
                          >
                            <i
                              class="icon-feather-trash-2 pointer"
                              v-on:click="removeAnnualLeave()"
                            ></i>
                          </div> -->
                      </div>
                      <div v-for="item in listLeaveDetail" :key="item.id" class="div-input">

                        <div class="group-left">
                          <label>Start Date</label>
                          <div class="input-group">
                            <input v-model="item.startDate" step="0" readonly class="form-control" type="text" />
                          </div>
                        </div>
                        <div class="group-right pl-0">
                          <label>End Date</label>
                          <div class="input-group">
                            <input v-model="item.endDate" type="text" step="0" readonly class="form-control" />
                          </div>
                        </div>
                        <div class="group-right">
                          <label>Total Hours</label>
                          <div class="input-group">
                            <input v-model.number="item.totalHours" type="number" step="0" readonly
                              class="form-control" />
                          </div>
                        </div>

                      </div>
                    </div>


                    <div class="group" v-show="currentEmployees.listLeaveSubmited != undefined &&
                      currentEmployees.listLeaveSubmited.length > 0
                      ">
                      <div class="col-12">
                        <hr />
                      </div>
                      <div class="title-option">Leave Submited</div>
                      <div v-for="item in currentEmployees.listLeaveSubmited" :key="item.id">
                        <div v-if="item.type == 'Annual'">
                          <div class="div-input">
                            <div class="group-left">
                              <div class="input-group">
                                <label style="display: flex">Annual leave used
                                  <div class="text-danger text-right" style="
                                      margin-left: 10px;
                                      display: flex;
                                      align-items: center;
                                    "></div>
                                </label>
                                <div class="input-group">
                                  <input v-model.number="item.totalHours" disabled type="number" step="0"
                                    class="form-control" maxlength="3" />
                                  <div class="unit">hr</div>
                                </div>
                                <div class="small-label">
                                  <span class="light-blue-bg text-black ng-binding">{{
                                    round(
                                      currentEmployees.totalAnnualLeave -
                                      currentEmployees.totalAnnualLeaveUsed
                                    )
                                  }}hr remaining</span>
                                </div>
                              </div>
                            </div>
                            <div class="group-right">
                              <div class="form-group">
                                <label for="">Leave Period</label>
                                <input v-model="item.listAllDate" disabled type="text" class="form-control" />
                              </div>
                            </div>
                          </div>
                          <div class="div-input" v-show="item.annualLeaveLoading > 0">
                            <div class="group-left">
                              <div class="input-group">
                                <div class="payroll-input-container ng-scope">
                                  <div class="input-group" style="margin-bottom: 3px">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">$</div>
                                    </div>
                                    <input v-model.number="item.annualLeaveLoading" type="number" step="0" class="
                                        form-control
                                        ng-pristine
                                        ng-untouched
                                        ng-valid
                                        ng-not-empty
                                        ng-valid-step
                                      " disabled="" />
                                  </div>
                                  <div class="small-label">
                                    Leave Loading
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else class="div-input">
                          <div class="group-left">
                            <label style="display: flex">Sick leave used
                              <div class="text-danger text-right" style="
                                  margin-left: 10px;
                                  display: flex;
                                  align-items: center;
                                "></div>
                            </label>
                            <div class="input-group">
                              <input v-model.number="item.totalHours" disabled type="number" step="0"
                                class="form-control" maxlength="3" />
                              <div class="unit">hr</div>
                            </div>
                            <div class="small-label">
                              <span class="light-blue-bg text-black ng-binding">{{
                                round(
                                  currentEmployees.totalSickLeave -
                                  currentEmployees.totalSickLeaveUsed
                                )
                              }}hr remaining</span>
                            </div>
                          </div>
                          <div class="group-right">
                            <div class="form-group">
                              <label for="">Leave Period</label>
                              <input v-model="item.listAllDate" disabled type="text" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Super Salary Sacrifice -->
                    <div class="group">
                      <div v-if="hasSuperSalary">
                        <div class="col-12">
                          <hr />
                        </div>
                        <div class="header">
                          <div class="title-option mr-3">
                            Super Salary Sacrifice
                          </div>
                          <div class="text-danger text-right" ng-hide="payslip.co" style="margin-top: 0px">
                            <i class="icon-feather-trash-2 pointer" v-on:click="removeSalarySacrifice()"></i>
                          </div>
                        </div>
                        <div class="div-input">
                          <div class="input-group" style="width: 100%">
                            <input v-on:blur="OnSalarySacrificeOut" v-model.number="superSalary" type="number" step="0"
                              class="form-control" />
                            <div class="unit">$</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group" v-show="isAnnualLeaveUsed || isSickLeaveUsed">
                      <div class="col-12">
                        <hr />
                      </div>
                      <div class="title-option">Leave</div>
                      <div v-show="isAnnualLeaveUsed">
                        <div class="div-input">
                          <div class="group-left">
                            <div class="input-group">
                              <label style="display: flex">Annual leave used
                                <div class="text-danger text-right" style="
                                    margin-left: 10px;
                                    display: flex;
                                    align-items: center;
                                  ">
                                  <i class="icon-feather-trash-2 pointer" v-on:click="removeAnnualLeave()"></i>
                                </div>
                              </label>
                              <div class="input-group">
                                <input v-model.number="annualLeaveUsed" v-bind:class="calculateDateAnnual > 1
                                  ? 'disabled-address'
                                  : ''
                                  " v-on:change="onChangeAnnualLeaveUsed()" type="number" step="0"
                                  class="form-control ng-annual" maxlength="3" />
                                <div class="unit">hr</div>
                              </div>
                              <div class="small-label">
                                <span class="light-blue-bg text-black ng-binding">{{
                                  round(
                                    currentEmployees.totalAnnualLeave -
                                    currentEmployees.totalAnnualLeaveUsed
                                  )
                                }}hr remaining</span>
                              </div>
                            </div>
                          </div>
                          <div class="group-right">
                            <div class="form-group">
                              <label for="">Leave Period
                                <span class="text-danger">*</span></label>
                              <date-picker class="datetime ng-dateannual" v-model="dateAnnualLeave"
                                :default-value="new Date(startPayPeriod)" :disabled-date="disabledBeforeTodayAndAfter"
                                :disabled="isDisableLeaveUsed" v-bind:class="calculateDateSick > 1
                                  ? 'disabled-address'
                                  : ''
                                  " @close="closeDatePickerAnnualLeave()" type="date" style="width: 100%" range
                                placeholder="Select date range" value-type="YYYY-MM-DD"
                                format="DD/MM/YYYY"></date-picker>
                            </div>
                          </div>
                        </div>
                        <div class="div-input" v-show="annualLeaveLoading > 0">
                          <div class="group-left">
                            <div class="input-group">
                              <div class="payroll-input-container ng-scope">
                                <div class="input-group" style="margin-bottom: 3px">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">$</div>
                                  </div>
                                  <input v-model.number="annualLeaveLoading" type="number" step="0" class="
                                      form-control
                                      ng-pristine
                                      ng-untouched
                                      ng-valid
                                      ng-not-empty
                                      ng-valid-step
                                    " disabled="" />
                                </div>
                                <div class="small-label">
                                  Leave Loading
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-show="isSickLeaveUsed" class="div-input">
                        <div class="group-left">
                          <label style="display: flex">Sick leave used
                            <div class="text-danger text-right" style="
                                margin-left: 10px;
                                display: flex;
                                align-items: center;
                              ">
                              <i class="icon-feather-trash-2 pointer" v-on:click="removeSickLeave()"></i>
                            </div>
                          </label>
                          <div class="input-group">
                            <input v-model.number="sickLeaveUsed" v-bind:class="calculateDateSick > 1 ? 'disabled-address' : ''
                              " type="number" @change="onChangeLeave" step="0" class="form-control ng-sick"
                              maxlength="3" />
                            <div class="unit">hr</div>
                          </div>
                          <div class="small-label">
                            <span class="light-blue-bg text-black ng-binding">{{
                              round(
                                currentEmployees.totalSickLeave -
                                currentEmployees.totalSickLeaveUsed
                              )
                            }}hr remaining</span>
                          </div>
                        </div>
                        <div class="group-right">
                          <div class="form-group">
                            <label for="">Leave Period
                              <span class="text-danger">*</span></label>
                            <date-picker class="datetime ng-datesick" v-model="dateSickLeave"
                              :default-value="new Date(startPayPeriod)" :disabled-date="disabledBeforeTodayAndAfter"
                              :disabled="isDisableSickUsed" @close="closeDatePickerSickLeave()" style="width: 100%"
                              type="date" range placeholder="Select date range" value-type="YYYY-MM-DD"
                              format="DD/MM/YYYY"></date-picker>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="group">
                      <div v-if="hasNote">
                        <div class="col-12">
                          <hr />
                        </div>
                        <div class="header">
                          <div class="title-option mr-3">Note</div>
                          <div class="text-danger text-right" ng-hide="payslip.co" style="margin-top: 0px">
                            <i class="icon-feather-trash-2 pointer" v-on:click="removeNote()"></i>
                          </div>
                        </div>
                        <div class="div-input">
                          <div class="form-group" style="width: 100%">
                            <textarea v-model="note" class="form-control" rows="3"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal-footer">
                  <div class="col-sm-6" style="text-align: left">
                    <b style="color: #7c7c7c; font-size: 11px">Gross Earnings</b>
                    <div class="gross">
                      <span class="txtDola">${{ getEarnings() }}</span>
                      <span class="fs-14" style="vertical-align: middle; width: 100%">+
                        <span @click="seeSuperDetail()" class="text-primary box-title super-detail"
                          style="vertical-align: middle; cursor: pointer">
                          ${{
                            utils.roundNumber(
                              superOgirin + employerContribution
                            )
                          }}
                          <!-- + superSalary  --> <i class="icon-feather-help-circle text-primary fs-14"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="ly-button">
                    <el-dropdown style="padding: 0px" @command="handleCommand">
                      <el-button class="btn btn-add-more" style="height: 40px; margin-right: 10px">
                        More<i class="el-icon-arrow-down el-icon--right"></i>
                      </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <div>
                          <el-dropdown-item v-for="item in listDropdown" :key="item !== false && item.id" v-bind:class="currentEmployees.employeeData.employmentType ==
                            'Casual' &&
                            (item.name == 'Annual leave' ||
                              item.name == 'Sick leave')
                            ? 'box-title disable-leave disabled'
                            : ''
                            " v-bind:style="item.name == 'Termination' ? 'color: red' : ''
                              " v-show="!item.hide" :icon="'el-icon-' + item.icon" :command="item.name"
                            :divided="item.line">
                            {{ item !== false ? item.name : '' }}
                          </el-dropdown-item>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <button v-click-outside="changePayRoll" :disabled="!changeData" class="btn btn-primary"
                      style="height: 40px" @click="clickSave()">
                      Save &amp; Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal name="modal-warning" with="auto" height="auto" style="z-index: 2000">
      <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog"
        aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" tabindex="-1"
        uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true"
        style="z-index: 1050; display: block; overflow: auto">
        <div class="modal-dialog modal-md">
          <div class="modal-content" uib-modal-transclude="">
            <div class="
                modal-content
                text-center
                onboarding-modal
                modal-centered
                ng-scope
              ">
              <div class="modal-content" style="height: 230px">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel" style="text-align: center">
                    <span class="ng-binding">Warning unsaved data</span>
                  </h5>
                  <button v-on:click="$modal.hide('modal-warning')" aria-label="Close" class="box-title close"
                    type="button"></button>
                </div>
                <div class="modal-body employee-modal text-left">
                  <label class="alert alert-warning" style="width: 100%">Do you want to save this change?</label>
                </div>
                <div class="modal-footer">
                  <button @click="clickSave()" class="btn btn-primary" style="margin-left: 15px">
                    Yes
                  </button>
                  <button @click="closeModal()" class="btn btn-cancel btn-grey">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal name="modal-super-detail" @before-open="beforeOpenSuperDetail" :clickToClose="false" with="auto"
      height="auto" style="z-index: 2000">
      <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog"
        aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" tabindex="-1"
        uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true"
        style="z-index: 1050; display: block; overflow: auto">
        <div class="modal-dialog modal-md">
          <div class="modal-content" uib-modal-transclude="">
            <div class="
                modal-content
                text-center
                onboarding-modal
                modal-centered
                ng-scope
              ">
              <div class="modal-content" style="height: max-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel" style="text-align: center">
                    <span class="ng-binding">Super Details</span>
                  </h5>
                  <button v-on:click="closeSuper()" aria-label="Close" class="box-title close" type="button"></button>
                </div>
                <div class="modal-body employee-modal text-left">
                  <table class="table table-padded">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th class="text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody class="ng-scope tr-group">
                      <tr class="transparent">
                        <td class="ng-binding">Super</td>
                        <td class="text-right ng-binding">
                          ${{ utils.roundNumber(superSalary) }}
                        </td>
                      </tr>
                      <tr class="transparent">
                        <td class="ng-binding">Employer Contribution</td>
                        <td class="text-right ng-binding">
                          ${{ utils.roundNumber(employerContribution) }}
                        </td>
                      </tr>
                      <!-- <tr class="transparent">
                        <td class="ng-binding">Super Salary Sacrifice</td>
                        <td class="text-right ng-binding">
                          ${{ utils.roundNumber(superSalary) }}
                        </td>
                      </tr> -->
                    </tbody>
                    <tbody>
                      <tr class="tr-total">
                        <td class="ng-binding">Total</td>
                        <td class="text-right ng-binding">
                          ${{
                            utils.roundNumber(
                              superSalary + employerContribution
                            )
                          }}
                        </td> <!-- + superSalary  -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal name="modal-ETP-earning" @before-open="beforeOpenETPEarning" with="auto" height="auto" style="z-index: 2000">
      <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog"
        aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" tabindex="-1"
        uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true"
        style="z-index: 1050; display: block; overflow: auto">
        <div class="modal-dialog modal-md">
          <div class="modal-content" uib-modal-transclude="">
            <div class="
                modal-content
                text-center
                onboarding-modal
                modal-centered
                ng-scope
              ">
              <div class="modal-content" style="height: max-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel" style="text-align: center">
                    <span class="ng-binding">Total Earning</span>
                  </h5>
                  <button v-on:click="$modal.hide('modal-ETP-earning')" aria-label="Close" class="box-title close"
                    type="button"></button>
                </div>
                <div class="modal-body employee-modal text-left">
                  <table class="table table-padded">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th class="text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody class="ng-scope tr-group">
                      <tr class="transparent">
                        <td class="ng-binding">Gross Earning</td>
                        <td class="text-right ng-binding">
                          ${{ utils.roundNumber(earnings) }}
                        </td>
                      </tr>
                      <tr class="transparent">
                        <td class="ng-binding">Unused Leave (Lump sum A)</td>
                        <td class="text-right ng-binding">
                          ${{ utils.truncateNumber(unusedLeave) }}
                        </td>
                      </tr>
                      <!-- <tr v-if="etpTypeR != 0" class="transparent">
                                            <td class="ng-binding">Redundancy</td>
                                            <td class="text-right ng-binding">${{utils.roundNumber(etpTypeR)}}</td>
                                        </tr> -->
                      <tr v-if="etpCodeR != 0" class="transparent">
                        <td class="ng-binding">Redundancy (Type 'R')</td>
                        <td class="text-right ng-binding">
                          ${{ utils.roundNumber(etpCodeR) }}
                        </td>
                      </tr>
                      <tr v-if="lumSumD != 0" class="transparent">
                        <td class="ng-binding">Redundancy (Lump sum D)</td>
                        <td class="text-right ng-binding">
                          ${{ utils.roundNumber(lumSumD) }}
                        </td>
                      </tr>
                      <tr v-if="etpTypeO != 0" class="transparent">
                        <td class="ng-binding">ETP Type 'O'</td>
                        <td class="text-right ng-binding">
                          ${{ utils.roundNumber(etpTypeO) }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr class="tr-total">
                        <td class="ng-binding">Total</td>
                        <td class="text-right ng-binding">
                          ${{ utils.roundNumber(amountETP) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal name="modal-ETP-tax" @before-open="beforeOpenETPTax" with="auto" height="auto" style="z-index: 2000">
      <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog"
        aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" tabindex="-1"
        uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true"
        style="z-index: 1050; display: block; overflow: auto">
        <div class="modal-dialog modal-md">
          <div class="modal-content" uib-modal-transclude="">
            <div class="
                modal-content
                text-center
                onboarding-modal
                modal-centered
                ng-scope
              ">
              <div class="modal-content" style="height: max-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel" style="text-align: center">
                    <span class="ng-binding">Withholding Tax</span>
                  </h5>
                  <button v-on:click="$modal.hide('modal-ETP-tax')" aria-label="Close" class="box-title close"
                    type="button"></button>
                </div>
                <div class="modal-body employee-modal text-left">
                  <table class="table table-padded" style="bo">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th class="text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody class="ng-scope tr-group">
                      <tr class="transparent">
                        <td class="ng-binding">Tax for Gross Earning</td>
                        <td class="text-right ng-binding">
                          ${{ utils.roundNumber(tax) }}
                        </td>
                      </tr>
                      <tr class="transparent">
                        <td class="ng-binding">Unused Leave (Lump sum A)</td>
                        <td class="text-right ng-binding">
                          ${{ utils.truncateNumber(unusedLeave) }}
                        </td>
                      </tr>
                      <tr v-if="taxETPTypeR != 0" class="transparent">
                        <td class="ng-binding">Tax ETP Type 'R'</td>
                        <td class="text-right ng-binding">
                          ${{ utils.roundNumber(taxETPTypeR) }}
                        </td>
                      </tr>
                      <tr v-if="taxETPTypeO != 0" class="transparent">
                        <td class="ng-binding">Tax ETP Type 'O'</td>
                        <td class="text-right ng-binding">
                          ${{ utils.roundNumber(taxETPTypeO) }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr class="tr-total">
                        <td class="ng-binding">Total</td>
                        <td class="text-right ng-binding">
                          ${{ utils.roundNumber(amountETP) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <div id="popupAlertHolidaysMORE" class="ng-confirm ng-confirm-white ng-confirm-type-default hide-popup">
      <div class="ng-confirm-bg"
        style="transition-duration:0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);"></div>
      <div class="ng-confirm-scrollpane">
        <div class="ng-bs3-container container">
          <div class="
              ng-bs3-row
              row
              justify-content-md-center
              justify-content-sm-center
              justify-content-xs-center
              justify-content-lg-center
            ">
            <div class="
                ng-confirm-box-p
                col-md-4
                col-md-offset-4
                col-sm-6
                col-sm-offset-3
                col-xs-10
                col-xs-offset-1
              ">
              <div class="
                  ng-confirm-box
                  ng-confirm-hilight-shake
                  ng-confirm-type-animated
                " role="dialog" aria-labelledby="ng-confirm-box187226" tabindex="-1" style="
                  margin-top: 257.663px;
                  margin-bottom: 0px;
                  transition-duration: 0.4s;
                  transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);
                ">
                <div class="ng-confirm-closeIcon ng-confirm-el-hide">
                  <span>×</span>
                </div>
                <div class="ng-confirm-title-c">
                  <span class="ng-confirm-icon-c ng-confirm-el-hide"><i></i></span><span
                    class="ng-confirm-title">Alert</span>
                </div>
                <div class="ng-confirm-content-pane" style="height: 86.4px;
                    transition-duration: 0.4s;
                    transition-timing-function: cubic-bezier(0.36,0.55,0.19,1);">
                  <div class="ng-confirm-content" id="ng-confirm-box187226">
                    <div class="ng-scope">
                      The date range you choose has weekends or public holidays?
                    </div>
                  </div>
                </div>
                <div class="ng-confirm-buttons">

                  <button v-on:click="hideAlertHolidays()" type="button" class="btn btn-default">
                    <span class="ng-confirm-btn-text">close</span>
                  </button>
                </div>
                <div class="ng-confirm-clear"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import * as Validate from "@/utils/Validate.js";
import * as Utils from "@/utils/Utils.js";
import * as Encode from "@/utils/Encodepassword";
import * as Session from "@/utils/SessionStore";
import * as RunPayRoll from "../../runpayroll/RunPayRoll";

import { GET_LIST_HOLIDAYS } from "@/store/actionsType";
import ApiManager from '@/api/ApiManager.js';
import moment from "moment";
import log4javascript from 'log4javascript';

export default {
  name: "PaymentDetailModal",
  props: [],
  components: {
    DatePicker,
  },
  data() {
    return {
      terminationDate: null,
      utils: Utils,
      runPayRoll: RunPayRoll,
      currentIndex: 0,
      changeData: false,
      changeHours: 0,
      isVisibeChangeData: false,
      changePayRoll: {
        handler: this.checkDataPayRoll,
        middleware: false,
        events: ["click", "dblclick", "change"],
      },
      oldData: [],
      currentEmployees: "",
      listHoidays: [],
      listAllHoidays: [],
      listBonous: [],
      listExtraHours: [],
      listAllowance: [],
      listTypeAllowance: [],
      listDeduction: [],
      listTermination: [],
      listDropdown: [],
      listLeaveDetail: [],
      listFN: [
        {
          id: 1,
          name: "30/03/2020-12/04/2020 (FN01)",
          code: "FN01",
        },
        {
          id: 2,
          name: "13/04/2020-26/04/2020 (FN02)",
          code: "FN02",
        },
        {
          id: 3,
          name: "27/04/2020-10/05/2020 (FN03)",
          code: "FN03",
        },
        {
          id: 4,
          name: "11/05/2020-24/05/2020 (FN04)",
          code: "FN04",
        },
        {
          id: 5,
          name: "25/05/2020-07/06/2020 (FN05)",
          code: "FN05",
        },
        {
          id: 6,
          name: "08/06/2020-21/06/2020 (FN06)",
          code: "FN06",
        },
        {
          id: 7,
          name: "22/06/2020-05/07/2020 (FN07)",
          code: "FN07",
        },
        {
          id: 8,
          name: "06/07/2020-19/07/2020 (FN08)",
          code: "FN08",
        },
        {
          id: 9,
          name: "20/07/2020-02/08/2020 (FN09)",
          code: "FN09",
        },
        {
          id: 10,
          name: "03/08/2020-16/08/2020 (FN10)",
          code: "FN10",
        },
        {
          id: 11,
          name: "17/08/2020-30/08/2020 (FN11)",
          code: "FN11",
        },
        {
          id: 12,
          name: "31/08/2020-13/09/2020 (FN12)",
          code: "FN12",
        },
        {
          id: 13,
          name: "14/09/2020-27/09/2020 (FN13)",
          code: "FN13",
        },
      ],
      hadJobkeeper: false,
      start: "",
      noteStarted: "",
      finish: "",
      noteFinished: "",
      startPayPeriod: "",
      endPayPeriod: "",

      // Leave
      isAnnualLeaveUsed: false,
      isSickLeaveUsed: false,
      isCheckValidate: true,
      annualLeaveLoading: 0,
      isDisableLeaveUsed: false,

      // Annual Leave
      dateAnnualLeave: "",
      calculateDateAnnual: 2,
      startDateAnnualLeave: "",
      endDateAnnualLeave: "",
      annualLeaveUsed: 0,
      isDisableSickUsed: false,

      // Sick Leave
      dateSickLeave: "",
      calculateDateSick: 2,
      startDateSickLeave: "",
      endDateSickLeave: "",
      sickLeaveUsed: 0,
      unusedLeave: 0,

      // etp earning
      etpTypeR: 0,
      lumSumD: 0,
      etpCodeR: 0,
      etpTypeO: 0,

      // etp tax
      taxETPTypeO: 0,
      taxETPTypeR: 0,
      amountETP: 0,
      earnings: 0,
      tax: 0,

      // super detail
      superOgirin: 0,
      superSalary: 0,
      employerContribution: 0,
      superTotal: 0,
      isSeeSuper: false,
      hasSuperSalary: false,

      // Note
      hasNote: false,
      note: "",
    };
  },

  methods: {
    Onload() {
      this.superOgirin = this.currentEmployees.super;
      this.superSalary = 0;
      this.superSalary =
        this.currentEmployees.employeeData.superSalarySacrifice;
      this.employerContribution =
        this.currentEmployees.employeeData.employerContribution;

      if (this.superSalary <= 0) {
        this.hasSuperSalary = false;
      } else {
        this.hasSuperSalary = true;
      }
    },

    beforeOpen(event) {
      if (!this.isSeeSuper) {
        this.changeData = false;

        this.currentIndex = event.params.currentIndex;
        this.currentEmployees = event.params.currentEmployees;
        this.currentEmployees.listLeaveSubmited.forEach((element) => {
          element.listAllDate =
            this.getDate2(element.startDate) +
            " ~ " +
            this.getDate2(element.endDate);
          if (element.type == "Annual") {
            element.annualLeaveLoading = this.getLeaveLoading(
              element.totalHours
            );
          }
        });
        if (this.currentEmployees.listLeaveAddMore.length > 0) {
          this.currentEmployees.listLeaveAddMore.forEach((element) => {
            if (element.type == "Annual") {
              this.isAnnualLeaveUsed = true;
              this.annualLeaveUsed = element.totalHours;
              this.dateAnnualLeave = [
                this.getDate(element.startDate),
                this.getDate(element.endDate),
              ];
            } else {
              this.isSickLeaveUsed = true;
              this.sickLeaveUsed = element.totalHours;
              this.dateSickLeave = [
                this.getDate(element.startDate),
                this.getDate(element.endDate),
              ];
            }
          });
          this.annualLeaveLoading =
            this.currentEmployees.annualLeaveLoading -
            this.currentEmployees.annualLeaveLoadingOrigin;
        } else {
          this.isAnnualLeaveUsed = false;
          this.annualLeaveUsed = 0;
          this.dateAnnualLeave = "";
          this.isSickLeaveUsed = false;
          this.sickLeaveUsed = 0;
          this.dateSickLeave = "";
          this.annualLeaveLoading = 0;
          this.currentEmployees.annualLeaveLoadingOrigin =
            this.currentEmployees.annualLeaveLoading;
        }
        this.note = this.currentEmployees.note;

        if (this.note != null && this.note != "") {
          this.hasNote = true;
        } else {
          this.hasNote = false;
        }

        this.startPayPeriod = event.params.startPayPeriod;
        this.endPayPeriod = event.params.endPayPeriod;

        Session.set("oldData", event.params.currentEmployees);
        this.oldData = Session.get("oldData");
        Session.remove("oldData");
        (this.listDropdown = [
          // {
          //   id: 1,
          //   name: "Bonus/Commission",
          //   hide: false,
          //   icon: "circle-plus",
          //   line: false,
          // },
          // this.currentEmployees.listExtraHours.length>0 ? {
          //   id: 2,
          //   name: "Extra hours",
          //   hide: false,
          //   icon: "circle-plus",
          //   line: false,
          // }:{},

          // this.currentEmployees.listAllowance.length>0 ?{
          //   id: 3,
          //   name: "Allowance",
          //   hide: false,
          //   icon: "circle-plus",
          //   line: false,
          // }:{},
          // {
          //   id: 4,
          //   name: "JobKeeper Payment",
          //   hide: false,
          //   icon: "circle-plus",
          //   line: false,
          // },
          {
            id: 5,
            name: "Annual leave",
            hide: this.isAnnualLeaveUsed,
            icon: "circle-plus",
            line: false,
          },
          {
            id: 6,
            name: "Sick leave",
            hide: this.isSickLeaveUsed,
            icon: "circle-plus",
            line: false,
          },
          // this.currentEmployees.listDeduction.length>0 ?{
          //   id: 7,
          //   name: "Deduction",
          //   hide: false,
          //   icon: "remove",
          //   line: true,
          // }:{},
          // {
          //   id: 10,
          //   name: "Super Salary Sacrifice",
          //   hide: false,
          //   icon: "remove",
          //   line: false,
          // },
          {
            id: 8,
            name: "Termination",
            hide: false,
            icon: "error",
            line: true,
          },
          {
            id: 9,
            name: "Note",
            hide: this.hasNote,
            icon: "s-comment",
            line: false,
          },


        ]),
          this.getDetailEmployees(event);

        let dropdownListingarray = this.listDropdown;
        this.listDropdown = [];
        this.listDropdown = dropdownListingarray.filter(value => JSON.stringify(value) !== '{}');

        if (this.listHoidays.length === 0) {
          this.getListHolidays();
        }
      } else {
        this.isSeeSuper = false;
      }
      this.Onload();
      this.getSuper();
    },

    seeSuperDetail() {
      this.$modal.hide("modal-paymentdetail");
      this.isSeeSuper = true;
      this.$modal.show("modal-super-detail", {
        superSalary: this.superSalary,
        employerContribution:
          this.currentEmployees.employeeData.employerContribution,
        superTotal: this.superTotal,
      });
    },

    closeSuper() {
      this.$modal.hide("modal-super-detail");
      if (this.isSeeSuper) {
        this.$modal.show("modal-paymentdetail");
      }
    },

    beforeOpenSuperDetail(event) {
      this.superOgirin = event.params.superOgirin;
      this.superSalary = event.params.superSalary;
      this.employerContribution = event.params.employerContribution;
      this.superTotal = event.params.superTotal;

      if (this.currentEmployees) {
        this.getSuper();
      }
    },

    beforeOpenETPEarning(event) {
      this.unusedLeave = event.params.terminationPay;
      this.etpTypeR = event.params.etpTypeR;
      this.lumSumD = event.params.lumSumD;
      this.etpCodeR = event.params.etpCodeR;
      this.etpTypeO = event.params.etpTypeO;
      this.earnings = event.params.earnings;
      this.amountETP =
        this.earnings +
        this.unusedLeave +
        this.lumSumD +
        this.etpCodeR +
        this.etpTypeO;
    },

    beforeOpenETPTax(event) {
      this.unusedLeave = event.params.taxTerminationPay;
      this.taxETPTypeO = event.params.taxETPTypeO;
      this.taxETPTypeR = event.params.taxETPTypeR;
      this.amountETP = event.params.tax;
      this.tax =
        this.amountETP -
        (this.unusedLeave + this.taxETPTypeO + this.taxETPTypeR);
    },

    disabledBeforeTodayAndAfter(date) {
      const startDate = new Date(this.startPayPeriod);
      startDate.setHours(0, 0, 0, 0);
      return date < startDate || date > new Date(this.endPayPeriod);
    },

    getDate(inputDate) {
      var date = new Date(inputDate);
      var day = date.getDate();
      var mount = date.getMonth() + 1;
      var year = date.getFullYear();
      if (day.toString().length == 1) {
        day = "0" + day;
      }
      if (mount.toString().length == 1) {
        mount = "0" + mount;
      }
      return year + "-" + mount + "-" + day;
    },

    getDate2(inputDate) {
      return Utils.formatDateToString(inputDate);
    },

    checkDataPayRoll() {
      var codeCurrentEmployees = Encode.encrypt(this.currentEmployees);
      var codeOldData = Encode.encrypt(this.oldData);
      if (codeOldData != codeCurrentEmployees) {
        this.changeData = true;
      } else {
        this.changeData = true;
      }
    },

    openWarning() {
      if (this.changeData) {
        this.$modal.show("modal-warning");
      } else {
        this.$modal.hide("modal-paymentdetail");
      }
    },

    closeModal() {
      this.resetWorkHours();
      this.resetPayRate();
      this.resetSuperFeilds();
      this.currentEmployees = this.oldData;
      if (this.$global.path == "/runpayroll") {
        this.$root.$refs.RunPayroll.clickSave(
          this.currentEmployees,
          this.currentIndex
        );
      } else {
        this.$root.$refs.UpdateRunPayroll.clickSave(
          this.currentEmployees,
          this.currentIndex
        );
      }
      this.$modal.hide("modal-warning");
      this.$modal.hide("modal-paymentdetail");
    },

    round(num) {
      var floor = Math.round(num * 100) / 100;
      if (num >= floor + 0.005) {
        floor = Math.round((floor + 0.01) * 100) / 100;
      }
      if (floor == Math.floor(floor)) {
        floor += ".00";
      } else if (floor * 10 == Math.floor(floor * 10)) {
        floor += "0";
      }

      return floor;
    },

    formatDateNumbers(inputDate) {
      if (!inputDate) return "-";
      return Utils.formatDateToString(inputDate);
    },

    formatDate(date) {
      return Utils.formatDateToString(date, "YYYY/MM/DD");
    },

    getDetailEmployees(event) {
      this.listBonous = this.currentEmployees.listBonous;
      if (this.listBonous.length > 0) {
        for (var i = 0; i < this.listBonous.length; i++) {
          if (this.listDropdown[i].name == "Bonus/Commission") {
            this.listDropdown[i].hide = true;
            break;
          }
        }
      }

      const empData = {
        employeeId: event.params.currentEmployees.employeeId,
        companyId: event.params.currentEmployees.companyId,
      };

      ApiManager.employeeTermination(empData)
        .then((response) => {
          this.terminationDate = response[0];
        }).catch((error) => {
          this.$logger.error(`getDetailEmployees: ${error}`)
        });



      if (!this.isVisibeChangeData) {
        const data = {
          startPayPeriod: moment(event.params.startPayPeriod).format("YYYY-MM-DD"),
          endPayPeriod: moment(event.params.endPayPeriod).format("YYYY-MM-DD"),
          employeeId: event.params.currentEmployees.employeeId,
          companyId: event.params.currentEmployees.companyId,
        }

        ApiManager.empLeaveDetail(data)
          .then((response) => {
            this.changeData = true;
            this.listExtraHours = response.empPayDetail.listExtraHours;
            if (this.listExtraHours.length) {
              this.changeData = false;
            }
            this.listExtraHours.map((extraHours) => {
              extraHours.name = extraHours.payslipName;
              extraHours.rate = extraHours.normalRate;
              extraHours.isExtraHourVisible = false;
            })
            this.listDeduction = response.empPayDetail.listDeduction;
            this.listDeduction.map((deduction) => {
              deduction.isDeductionVisible = false;
            })
            this.listAllowance = response.empPayDetail.listAllowance;
            this.listAllowance.map((allowance) => {
              allowance.isAllowanceVisible = false;
            })
            this.listLeaveDetail = response.empLeave;
            this.listLeaveDetail.map((leaveData) => {
              leaveData.startDate = moment(leaveData.startDate).format('DD/MM/YYYY')
              leaveData.endDate = moment(leaveData.endDate).format('DD/MM/YYYY')
            })
            this.isVisibeChangeData = true;
          }).catch((error) => {
            this.$logger.error(`getDetailEmployees - get emp leave: ${error}`)
          });
      }
      else {
        this.listExtraHours = this.currentEmployees.listExtraHours || [];
        this.listExtraHours.map((extraHours) => {
          extraHours.name = extraHours.payslipName;
          extraHours.rate = extraHours.normalRate;
          extraHours.isExtraHourVisible = false;
        })
        this.listDeduction = this.currentEmployees.listDeduction || [];
        this.listDeduction.map((deduction) => {
          deduction.isDeductionVisible = false;
        })
        this.listAllowance = this.currentEmployees.listAllowance || [];
        this.listAllowance.map((allowance) => {
          allowance.isAllowanceVisible = false;
        })
        this.listLeaveDetail = this.currentEmployees.listLeaveDetail || [];
      }

      this.hadJobkeeper = false;

      this.listAllowance.forEach((element) => {
        if (element.noteJobKeeper != null && element.noteJobKeeper != "") {
          this.hadJobkeeper = true;
          element.type = "JOBKEEPER";
          this.start = "";
          this.finish = "";
          this.noteStarted = element.noteStarted;
          this.noteFinished = element.noteFinished;
          if (this.noteStarted != "") {
            var codeStart = element.noteStarted.slice(-4);
            for (var i = 0; i < this.listFN.length; i++) {
              if (this.listFN[i].code == codeStart) {
                this.start = this.listFN[i];
              }
            }
          }
          if (this.noteFinished != "") {
            var codeFinish = element.noteFinished.slice(-4);
            for (i = 0; i < this.listFN.length; i++) {
              if (this.listFN[i].code == codeFinish) {
                this.finish = this.listFN[i];
              }
            }
          }

          for (i = 0; i < this.listDropdown.length; i++) {
            if (this.listDropdown[i].name == "JobKeeper Payment") {
              this.listDropdown[i].hide = true;
              break;
            }
          }
        }
      });

      this.listTermination = this.currentEmployees.listTermination;
      if (this.listTermination.length > 0) {
        for (i = 0; i < this.listDropdown.length; i++) {
          if (this.listDropdown[i].name == "Termination") {
            this.listDropdown[i].hide = true;
            break;
          }
        }
      }
    },

    handleCommand(command) {
      if (command == "Bonus/Commission") {
        this.listBonous.push({
          id: 0,
          type: "EXEMPT_TO_SUPER",
          amountBonus: 0,
        });
        this.listDropdown[0].hide = true;
      } else if (command == "Extra hours") {
        this.listExtraHours.push({
          id: 0,
          option: "ORDINARY_HOURS",
          name: "",
          hour: 0,
          rate: 0,
          normalRate: 0,
          isExtraHourVisible: true
        });
      } else if (command == "Allowance") {
        this.listAllowance.push({
          id: 0,
          unit: 1,
          cost: 0,
          option: "SUBJECT_TO_TAX_AND_SUPER",
          type: "CAR",
          noteJobKeeper: "",
          noteStarted: "",
          noteFinished: "",
          isAllowanceVisible: true,
        });
      } else if (command == "JobKeeper Payment") {
        this.hadJobkeeper = true;
        this.listAllowance.push({
          id: 0,
          unit: 1,
          cost: 0,
          option: "SUBJECT_TO_TAX_AND_SUPER",
          type: "JOBKEEPER",
          noteJobKeeper: "JOBKEEPER-TOPUP",
          noteStarted: "",
          noteFinished: "",
        });
        for (var i = 0; i < this.listDropdown.length; i++) {
          if (this.listDropdown[i].name == command) {
            this.listDropdown[i].hide = true;
            break;
          }
        }
      } else if (command == "Deduction") {
        this.listDeduction.push({
          id: 0,
          option: "POST_TAX_DEDUCTION",
          type: "FEES",
          amountDeduction: 0,
          isDeductionVisible: true
        });
      } else if (command == "Termination") {
        if (!this.terminationDate) {
          Utils.toastError("This employee has not been terminated");
          return;
        }
        var date = "";
        var now = new Date();
        var start = new Date(this.currentEmployees.startPayPeriod);
        var end = new Date(this.currentEmployees.endPayPeriod);
        if (now < start || now > end) {
          date = this.formatDate(end);
        } else {
          date = this.formatDate(now);
        }
        var unusedLeave = this.getUnusedLeave(date);
        this.listTermination.push({
          id: 0,
          terminationDate: this.terminationDate,
          type: "NORMAL",
          unusedLeave: this.truncateNumber(unusedLeave),
          amountTermination: this.truncateNumber(
            this.currentEmployees.employeeData.payRatePerHour
          ),
          redundancy: 0,
          etpType: 0,
          // add to run in web
          isShowETP: false,
        });
        for (i = 0; i < this.listDropdown.length; i++) {
          if (this.listDropdown[i].name == command) {
            this.listDropdown[i].hide = true;
            break;
          }
        }
      } else if (command == "Annual leave") {
        if (this.currentEmployees.employeeData.employmentType != "Casual") {
          this.isDisableLeaveUsed = false;
          this.isAnnualLeaveUsed = true;
          for (i = 0; i < this.listDropdown.length; i++) {
            if (this.listDropdown[i].name == command) {
              this.listDropdown[i].hide = true;
              break;
            }
          }
        }
      } else if (command == "Sick leave") {
        if (this.currentEmployees.employeeData.employmentType != "Casual") {
          this.isDisableSickUsed = false;
          this.isSickLeaveUsed = true;
          for (i = 0; i < this.listDropdown.length; i++) {
            if (this.listDropdown[i].name == command) {
              this.listDropdown[i].hide = true;
              break;
            }
          }
        }
      } else if (command == "Note") {
        this.hasNote = true;
        for (i = 0; i < this.listDropdown.length; i++) {
          if (this.listDropdown[i].name == "Note")
            this.listDropdown[i].hide = true;
        }
      } else if (command == "Super Salary Sacrifice") {
        this.hasSuperSalary = true;
        for (i = 0; i < this.listDropdown.length; i++) {
          if (this.listDropdown[i].name == "Super Salary Sacrifice")
            this.listDropdown[i].hide = true;
        }
        this.currentEmployees.superSalarySacrifice = this.superSalary;
        // this.currentEmployees.totalDeduction=  this.currentEmployees.totalDeduction+ this.superSalary
      }
      this.checkDataPayRoll();
    },

    onChangeWeeklyWorkHours() {
      if (
        this.currentEmployees.originWorkHours !=
        this.currentEmployees.ordinaryWorkHoursRound
      ) {
        this.currentEmployees.isChangeWorkHours = true;
      } else {
        this.currentEmployees.isChangeWorkHours = false;
      }
      this.currentEmployees.ordinaryWorkHours =
        this.currentEmployees.ordinaryWorkHoursRound;
      this.currentEmployees.ordinaryWorkHoursRound = this.getWorkHours();
      if (this.listTermination.length > 0) {
        this.listTermination.forEach((element) => {
          this.changeEndDate(element);
        });
      }
    },



    resetWorkHours() {
      this.currentEmployees.isChangeWorkHours = false;
      this.currentEmployees.ordinaryWorkHours =
        this.currentEmployees.originWorkHours;
      this.currentEmployees.ordinaryWorkHoursRound = this.getWorkHours();
      if (this.listTermination.length > 0) {
        this.listTermination.forEach((element) => {
          this.changeEndDate(element);
        });
      }
    },

    onChangePayRatePerHour() {
      if (
        this.currentEmployees.originPayRate !=
        this.currentEmployees.ordinaryPayRateRound
      ) {
        this.currentEmployees.isChangePayRate = true;
      } else {
        this.currentEmployees.isChangePayRate = false;
      }
      this.currentEmployees.ordinaryPayRate =
        this.currentEmployees.ordinaryPayRateRound;
      this.currentEmployees.ordinaryPayRateRound = this.getPayRate();
    },

    resetPayRate() {
      this.currentEmployees.isChangePayRate = false;
      this.currentEmployees.ordinaryPayRate =
        this.currentEmployees.originPayRate;
      this.currentEmployees.ordinaryPayRateRound = this.getPayRate();
    },

    resetSuperFeilds() {
      this.superOgirin = 0;
      this.superSalary = 0;
      this.superTotal = 0;
    },

    clickSave() {

      this.checkClickSave();
      if (!this.isCheckValidate) {
        return;
      }

      this.$modal.hide("modal-warning");

      const checkValidateResult = this.checkValidate();
      const validateInputResult = this.validateAllInputNumber();

      const shouldProceed = (this.$root.$refs.RunPayroll != undefined || this.$root.$refs.UpdateRunPayroll)
        && checkValidateResult
        && validateInputResult;

      if (shouldProceed) {
        this.updateDataEmployee();
        if (this.$global.path == "/runpayroll") {
          this.$root.$refs.RunPayroll.clickSave(
            this.currentEmployees,
            this.currentIndex
          );
        } else {
          this.$root.$refs.UpdateRunPayroll.clickSave(
            this.currentEmployees,
            this.currentIndex
          );
        }
        this.$modal.hide("modal-paymentdetail");
      }

    },

    updateDataEmployee() {
      this.currentEmployees.listBonous = this.listBonous;
      this.currentEmployees.listExtraHours = this.listExtraHours;
      this.listAllowance.forEach((element) => {
        if (element.type == "JOBKEEPER") {
          element.type = "OTHER";
          element.noteStarted = this.noteStarted;
          element.noteFinished = this.noteFinished;
        }
      });
      this.currentEmployees.listAllowance = this.listAllowance;
      this.currentEmployees.listDeduction = this.listDeduction;
      this.currentEmployees.listTermination = this.listTermination;
      this.currentEmployees.listLeaveDetail = this.listLeaveDetail;
      this.currentEmployees.note = this.note;

      this.updateLeave();
    },

    updateLeave() {
      console.log("update ", this.isAnnualLeaveUsed);
      this.$root.$emit("update-modal-used", {
        listBonous: this.listBonous,
        listExtraHours: this.listExtraHours,
        listAllowance: this.listAllowance,
        listDeduction: this.listDeduction,
        listTermination: this.listTermination,
        isAnnualLeaveUsed: this.isAnnualLeaveUsed,
        isSickLeaveUsed: this.isSickLeaveUsed,
        hasNote: this.hasNote,
        hasSuperSalary: this.hasSuperSalary
      });

      this.currentEmployees.listLeaveAddMore = [];

      if (this.isAnnualLeaveUsed) {
        this.currentEmployees.listLeaveAddMore.push({
          id: 0,
          type: "Annual",
          startDate: this.startDateAnnualLeave,
          endDate: this.endDateAnnualLeave,
          ListDateAccept: [],
          totalHours: this.annualLeaveUsed,
          workHoursAtTime: Session.get("workHours"),
          note: "",
        });
      }
      if (this.isSickLeaveUsed) {
        this.currentEmployees.listLeaveAddMore.push({
          id: 0,
          type: "Sick",
          startDate: this.startDateSickLeave,
          endDate: this.endDateSickLeave,
          ListDateAccept: [],
          totalHours: this.sickLeaveUsed,
          workHoursAtTime: Session.get("workHours"),
          note: "",
        });
      }
      this.currentEmployees.annualLeaveLoading =
        this.annualLeaveLoading +
        this.currentEmployees.annualLeaveLoadingOrigin;
    },

    onChangeLeave() {
      this.updateLeave();
    },

    getEarnings() {
      var earnings = this.runPayRoll.getEarnings(
        this.currentEmployees,
        this.listExtraHours,
        this.listBonous,
        this.listAllowance,
        this.listTermination
      );

      return this.utils.roundNumber(earnings);
    },

    getSuper() {
      var listExtraHours = this.listExtraHours;
      var listBonous = this.listBonous;
      var listAllowance = this.listAllowance;
      var earnings =
        this.currentEmployees.ordinaryWorkHours *
        this.currentEmployees.ordinaryPayRate;

      if (listExtraHours.length > 0) {
        for (var i = 0; i < listExtraHours.length; i++) {
          if (listExtraHours[i].option == "ORDINARY_HOURS") {
            earnings =
              earnings + listExtraHours[i].hour * listExtraHours[i].rate;
          }
        }
      }

      if (listBonous.length > 0) {
        for (var j = 0; j < listBonous.length; j++) {
          if (listBonous[j].type == "SUBJECT_TO_SUPER") {
            earnings = earnings + listBonous[j].amountBonus;
          }
        }
      }

      if (listAllowance.length > 0) {
        for (var x = 0; x < listAllowance.length; x++) {
          if (listAllowance[x].option == "SUBJECT_TO_TAX_AND_SUPER") {
            earnings = earnings + listAllowance[x].unit * listAllowance[x].cost;
          }
        }
      }

      this.superOgirin =
        (this.currentEmployees.contributionRate * earnings) / 100;
    },

    getWorkHours() {
      var result = this.utils.roundNumber(
        this.currentEmployees.ordinaryWorkHours
      );
      if (result == this.currentEmployees.ordinaryWorkHours) {
        return this.currentEmployees.ordinaryWorkHours;
      }
      return result;
    },

    getPayRate() {
      var result = this.utils.roundNumber(
        this.currentEmployees.ordinaryPayRate
      );
      if (result == this.currentEmployees.ordinaryPayRate) {
        return this.currentEmployees.ordinaryPayRate;
      }
      return result;
    },

    // Extra hours
    removeExtrahours(index) {
      this.listExtraHours.splice(index, 1);
    },

    // Allowance
    removeAllowance(index) {
      this.listAllowance.splice(index, 1);
    },

    choseTypeAllowance(value) {
      if (value.type == "JOBKEEPER") {
        this.hadJobkeeper = true;
        value.unit = 1;
        value.noteJobKeeper = "JOBKEEPER-TOPUP";
        this.chooseStartJobKeeper(this.start);
        this.chooseFinishJobKeeper(this.finish);
        for (var i = 0; i < this.listDropdown.length; i++) {
          if (this.listDropdown[i].name == "JobKeeper Payment") {
            this.listDropdown[i].hide = true;
            break;
          }
        }
      } else {
        if (value.noteJobKeeper != "") {
          this.hadJobkeeper = false;
        }
        value.noteJobKeeper = "";
        this.noteStarted = "";
        this.noteFinished = "";
        for (i = 0; i < this.listDropdown.length; i++) {
          if (this.listDropdown[i].name == "JobKeeper Payment") {
            this.listDropdown[i].hide = false;
            break;
          }
        }
      }
    },

    removeJobKeeper(index) {
      this.listAllowance.splice(index, 1);
      this.hadJobkeeper = false;
      for (var i = 0; i < this.listDropdown.length; i++) {
        if (this.listDropdown[i].name == "JobKeeper Payment")
          this.listDropdown[i].hide = false;
      }
    },

    chooseStartJobKeeper(value) {
      if (value != "") {
        this.noteStarted = "JOBKEEPER-START-" + value.code;
      } else {
        this.noteStarted = "";
      }
    },

    chooseFinishJobKeeper(value) {
      if (value != "") {
        this.noteFinished = "JOBKEEPER-FINISH-" + value.code;
      } else {
        this.noteFinished = "";
      }
    },

    // Deduction
    removeDeduction(index) {
      this.listDeduction.splice(index, 1);
    },

    // Termination
    removeTermination(index) {
      this.listTermination.splice(index, 1);
      for (var i = 0; i < this.listDropdown.length; i++) {
        if (this.listDropdown[i].name == "Termination")
          this.listDropdown[i].hide = false;
      }
    },

    removeNote() {
      this.note = "";
      this.hasNote = false;
      for (var i = 0; i < this.listDropdown.length; i++) {
        if (this.listDropdown[i].name == "Note")
          this.listDropdown[i].hide = false;
      }
    },

    removeSalarySacrifice() {
      this.superSalary = 0;
      this.hasSuperSalary = false;
      for (var i = 0; i < this.listDropdown.length; i++) {
        if (this.listDropdown[i].name == "Super Salary Sacrifice")
          this.listDropdown[i].hide = false;
      }
    },

    getTerminationPay() {
      return this.utils.roundNumber(
        this.runPayRoll.getTerminationPay(this.listTermination)
      );
    },

    changeEndDate(json) {
      var unusedLeave = this.getUnusedLeave(json.terminationDate);
      json.unusedLeave = this.truncateNumber(unusedLeave);
    },

    getUnusedLeave(endDate) {
      var start = new Date(this.currentEmployees.startPayPeriod);
      var end = new Date(endDate);
      var day = 0;
      while (start <= end) {
        if (start.getDay() != 0 && start.getDay() != 6) {
          day += 1;
        }
        start.setDate(start.getDate() + 1);
      }
      return (
        ((this.currentEmployees.ordinaryWorkHours / 5) * day * 4) / 52 +
        (this.currentEmployees.totalAnnualLeave -
          this.currentEmployees.totalAnnualLeaveUsed)
      );
    },

    truncateNumber(number) {
      return this.utils.truncateNumber(number);
    },

    // Annual leave
    removeAnnualLeave() {
      this.isAnnualLeaveUsed = false;
      for (var i = 0; i < this.listDropdown.length; i++) {
        if (this.listDropdown[i].name == "Annual leave")
          this.listDropdown[i].hide = false;
      }
      this.annualLeaveUsed = 0;
      this.dateAnnualLeave = "";
      this.annualLeaveLoading = 0;
      this.updateLeave();
    },

    closeDatePickerAnnualLeave() {
      this.startDateAnnualLeave = this.dateAnnualLeave[0];
      this.endDateAnnualLeave = this.dateAnnualLeave[1];

      if (
        this.startDateAnnualLeave != null ||
        this.endDateAnnualLeave != null
      ) {
        this.calculateDateAnnual = this.utils.calculateDateWithHolidays(
          this.startDateAnnualLeave,
          this.endDateAnnualLeave,
          this.listHoidays
        );
        this.annualLeaveUsed = this.leaveUsage(this.calculateDateAnnual);
      } else {
        this.calculateDateAnnual = 2;
        this.annualLeaveUsed = 0;
      }

      this.showPopUpHolidayWeekend(this.startDateAnnualLeave, this.endDateAnnualLeave)

      this.getLeaveLoading(this.annualLeaveUsed);
      this.updateLeave();
    },

    showPopUpHolidayWeekend(startDate, endDate) {
      var star = new Date(startDate);
      var end = new Date(endDate);
      var listHoidays = this.listHoidays;

      var holidayCount = 0;
      var weekendCount = 0;
      var curDate = star;
      while (curDate <= end) {
        var dayOfWeek = curDate.getDay();

        if (!(dayOfWeek == 6 || dayOfWeek == 0)) {
          for (var i = 0; i < listHoidays.length; i++) {
            var day = "";
            if (listHoidays[i].length >= 10) {
              day = listHoidays[i].substring(0, 10);
            } else {
              day = "";
            }
            var holiday = new Date(day);
            if (holiday.getTime() === curDate.getTime()) {

              holidayCount++;
              break;
            }
          }

        } else {
          weekendCount++;
        }
        curDate.setDate(curDate.getDate() + 1);
      }
      if (weekendCount > 0 || holidayCount > 0) {
        this.showAlertHolidays()
      }
    },

    showAlertHolidays() {
      var elem = window.$("#popupAlertHolidaysMORE");
      elem.removeClass("hide-popup");
    },

    hideAlertHolidays() {
      var elem = window.$("#popupAlertHolidaysMORE");
      elem.addClass("hide-popup");
    },

    onChangeAnnualLeaveUsed() {
      this.getLeaveLoading(this.annualLeaveUsed);
      this.updateLeave();
    },

    // sick Leave
    removeSickLeave() {
      this.isSickLeaveUsed = false;
      for (var i = 0; i < this.listDropdown.length; i++) {
        if (this.listDropdown[i].name == "Sick leave")
          this.listDropdown[i].hide = false;
      }
      this.dateSickLeave = "";
      this.sickLeaveUsed = 0;
      this.updateLeave();
    },

    closeDatePickerSickLeave() {
      this.startDateSickLeave = this.dateSickLeave[0];
      this.endDateSickLeave = this.dateSickLeave[1];
      if (this.startDateSickLeave != null || this.endDateSickLeave != null) {
        this.calculateDateSick = this.utils.calculateDateWithHolidays(
          this.startDateSickLeave,
          this.endDateSickLeave,
          this.listHoidays
        );
        this.sickLeaveUsed = this.leaveUsage(this.calculateDateSick);
      } else {
        this.calculateDateSick = 2;
        this.sickLeaveUsed = 0;
      }
      this.showPopUpHolidayWeekend(this.startDateSickLeave, this.endDateSickLeave)

      this.updateLeave();
    },

    leaveUsage(calculateDate) {
      var leaveUsage = 0;
      leaveUsage = calculateDate * Session.get("workHours");
      return Number(Utils.roundNumber(leaveUsage));
    },

    getLeaveLoading(annualLeaveUsed) {
      var leavelLoading = 0;
      if (this.currentEmployees.employeeData != undefined) {
        leavelLoading =
          (annualLeaveUsed *
            (this.currentEmployees.employeeData.payRatePerHour *
              this.currentEmployees.employeeData.leaveLoading)) /
          100;
      }
      this.annualLeaveLoading = Number(this.utils.roundNumber(leavelLoading));
      return this.annualLeaveLoading;
    },

    onChangeOptioneDuction(event, index) {
      var option = event.target.value;
      if (option != "PRE_TAX_DEDUCTION") {
        this.listDeduction[index].type = "OTHER";
      } else {
        this.listDeduction[index].type = "FEES";
      }
    },

    // Bonus
    removeBonus(index) {
      this.listBonous.splice(index, 1);
      for (var i = 0; i < this.listDropdown.length; i++) {
        if (this.listDropdown[i].name == "Bonus/Commission")
          this.listDropdown[i].hide = false;
      }
    },

    checkValidate() {
      window.$(".is-invalid").removeClass("is-invalid");
      if (this.listExtraHours.length > 0) {
        this.listExtraHours.forEach((element, index) => {
          var input = window.$(".ng-name" + index);
          if (Validate.isEmpty(element.name)) {
            input.addClass("is-invalid");
            return false;
          }

          var inputHour = window.$(".ng-hour" + index);

          if (Validate.isEmpty(element.hour)) {
            inputHour.addClass("is-invalid");
            return false;
          }
        });
      }

      if (this.listTermination.length > 0) {
        this.listTermination.forEach((element, index) => {
          var input = window.$(".ng-endDate" + index);
          var date = element.terminationDate;
          if (Validate.isEmpty(date)) {
            input.addClass("is-invalid");
            return false;
          } else {
            date = new Date(date + " 00:00:00");
            var start = new Date(this.currentEmployees.startPayPeriod);
            var end = new Date(this.currentEmployees.endPayPeriod);

            if (date < start || date > end) {
              input.addClass("is-invalid");
              return false;
            }
          }
        });
      }

      if (this.listAllowance.length > 0) {
        this.listAllowance.forEach((element) => {
          if (element.type == "JOBKEEPER") {
            var input = window.$(".ng-startFN");
            if (Validate.isEmpty(this.start)) {
              input.addClass("is-invalid");
              return false;
            }
            input = window.$(".ng-finishFN");
            if (
              !Validate.isEmpty(this.start) &&
              !Validate.isEmpty(this.finish) &&
              this.finish.id < this.start.id
            ) {
              input.addClass("is-invalid");
              return false;
            }
          }
        });
      }

      return true;
    },

    validateAllInputNumber() {
      var isValidate = true;
      var inputs = Array.from(window.$(':input[type="number"]'));
      inputs.forEach((element) => {
        if (Validate.isNumber(element.value)) {
          element.classList.add("is-invalid");
          isValidate = false;
        }
      });
      return isValidate;
    },

    getListHolidays() {
      this.$root.$refs.AppCyrus.start();
      var year = new Date().getFullYear();
      var request = {
        years: [year],
      };
      this.$store
        .dispatch(GET_LIST_HOLIDAYS, request)
        .then((response) => {
          this.$root.$refs.AppCyrus.finish();
          this.listAllHoidays = [];
          this.listAllHoidays = response.records;
          this.getListHolidaysForEmployee();
        })
        .catch((err) => {
          this.$logger.error(`getListHolidays: ${JSON.stringify(err)}`)
          this.$root.$refs.AppCyrus.fail();
        });
    },

    getListHolidaysForEmployee() {
      if (this.listHoidays.length == 0) {
        for (var i = 0; i < this.listAllHoidays.length; i++) {
          if (
            this.currentEmployees.employeeData.state.trim() ==
            this.listAllHoidays[i].jurisdiction.toUpperCase().trim()
          ) {
            this.listHoidays.push(this.listAllHoidays[i].date);
          }
        }
      }
    },

    /**
     * Check validate before click save. This set isCheckValidate to false if any field is invalid
     */
    checkClickSave() {
      this.isCheckValidate = true;
      if (this.isAnnualLeaveUsed) {
        var annual = window.$(".ng-annual");
        if (this.calculateDateAnnual === 1) {
          if (
            this.annualLeaveUsed === "" ||
            this.annualLeaveUsed > 24 ||
            this.annualLeaveUsed <= 0
          ) {
            annual.addClass("is-invalid");
            this.isCheckValidate = false;
          } else {
            annual.removeClass("is-invalid");
          }
        }
        annual = window.$(".ng-dateannual");
        if (this.dateAnnualLeave == [] || this.dateAnnualLeave == null) {
          annual.addClass("is-invalid");
          this.isCheckValidate = false;
        } else {
          annual.removeClass("is-invalid");
        }
      }
      if (this.isSickLeaveUsed) {
        var sick = window.$(".ng-sick");
        if (this.calculateDateSick === 1) {
          if (
            this.sickLeaveUsed === "" ||
            this.sickLeaveUsed > 24 ||
            this.sickLeaveUsed <= 0
          ) {
            sick.addClass("is-invalid");
            this.isCheckValidate = false;
          } else {
            sick.removeClass("is-invalid");
          }
        }
        sick = window.$(".ng-datesick");
        if (this.dateSickLeave == [] || this.dateSickLeave == null) {
          sick.addClass("is-invalid");
          this.isCheckValidate = false;
        } else {
          sick.removeClass("is-invalid");
        }
      }

    },

    OnSalarySacrificeOut() {
      this.checkDataPayRoll();
      this.calculateSuperAfterSuperUpdate();
    },

    calculateSuperAfterSuperUpdate() {
      var earnings =
        this.currentEmployees.ordinaryWorkHours *
        this.currentEmployees.ordinaryPayRate;
      var NewcountSuper =
        (this.currentEmployees.contributionRate * earnings) / 100;
      if (this.currentEmployees.employeeData != undefined) {
        NewcountSuper + this.currentEmployees.employeeData.employerContribution;
      }
      this.superTotal = NewcountSuper;
      this.currentEmployees.employeeData.superSalarySacrifice =
        this.superSalary;
    },
  },
  created: function () {
    this.$logger = log4javascript.getLogger("paymentDetailModal");
    this.$parent.$on("show-modal-data", (data) => {
      this.listBonous = data.listBonous;
      this.listExtraHours = data.listExtraHours;
      this.listAllowance = data.listAllowance;
      this.listDeduction = data.listDeduction;
      this.listTermination = data.listTermination;
      this.isAnnualLeaveUsed = data.isAnnualLeaveUsed;
      this.isSickLeaveUsed = data.isSickLeaveUsed;
      this.hasNote = data.hasNote;
      this.hasSuperSalary = data.hasSuperSalary;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
@import url("/assets/css/runpayroll.css");
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");

.el-dropdown {
  vertical-align: top;
}

.el-dropdown+.el-dropdown {
  margin-left: 15px;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.input.disabled-address {
  pointer-events: none;
  color: #aaa;
  background: #f5f5f5;
}

.hide-popup {
  display: none;
}
</style>
